<template>
  <div :style="{background: containerStyle?.backgroundColor || ''}">
    <!-- 吸顶无券新客样式 -->
    <NewUserSticky
      v-if="isSticky && isNewUserSticky(showItems)"
      :get-analysis-data="getAnalysisData"
      :show-items="showItems"
      :only-single-style-page="onlySingleStylePage"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <template
      v-for="(item, index) in items"
      v-else
    >
      <!-- 新客样式有券 -->
      <NewUserCoupon
        v-if="isHasNewUserCoupon(item)"
        :key="index"
        :style="getDynamicStyle(item)"
        :get-analysis-data="getAnalysisData" 
        :index="0"
        :item="item"
        :is-sticky="isSticky"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <FreeShipping
        v-if="item.type == 1"
        :key="item.type"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        :style="getDynamicStyle(item)"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
        :is-show-shipping-coupon="isShowShippingCoupon"
        :get-self-coupon="getSelfCoupon"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <CollectOrder 
        v-if="item.type == 2"
        :key="item.type"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        :style="getDynamicStyle(item)"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <ReturnCoupon 
        v-if="item.type == 3"
        :key="item.type"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        :style="getDynamicStyle(item)"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <SelfCoupon 
        v-if="item.type == 4 || item.type == 5"
        :key="`${item.type}-${index}`"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        :style="getDynamicStyle(item)"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <CouponCountdown
        v-if="item.type == 6"
        :key="item.type"
        ref="CouponCountdownSlide"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        :style="getDynamicStyle(item)"
        :index="index"
        :item="item"
        :is-sticky="isSticky"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <FreeShippingCoupon
        v-if="item.markFrame == '30'"
        :key="`${item.type}-${index}`"
        v-tap="getAnalysisData('2-22-2', { item, index, customComponentName, useBffApi: true })"
        v-expose="getAnalysisData('2-22-1', { item, index, customComponentName, useBffApi: true })"
        :index="index"
        :item="item"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
  </div>
</template>

<script>
// import FreeShipping from './FreeShipping.vue'
// import ReturnCoupon from './ReturnCoupon.vue'
// import CollectOrder from './CollectOrder.vue'
// import SelfCoupon from './SelfCoupon.vue'
// import CouponCountdown from './CouponCountdown.vue'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'

export default {
  name: 'FreeShippingList',
  components: {
    FreeShipping: () => import(/* webpackChunkName: "free-shipping-comp" */'./frame-component/FreeShipping.vue'),
    ReturnCoupon: () => import(/* webpackChunkName: "return-coupon-comp" */'./frame-component/ReturnCoupon.vue'),
    CollectOrder: () => import(/* webpackChunkName: "collect-order-comp" */'./frame-component/CollectOrder.vue'),
    SelfCoupon: () => import(/* webpackChunkName: "self-coupon-comp" */'./frame-component/SelfCoupon.vue'),
    CouponCountdown: () => import(/* webpackChunkName: "coupon-countdown-comp" */'./frame-component/CouponCountdown.vue'),
    NewUserCoupon: () => import(/* webpackChunkName: "new-user-coupon-comp" */'./frame-component/NewUserCoupon.vue'),
    NewUserSticky: () => import(/* webpackChunkName: "new-user-sticky-comp" */'./frame-component/NewUserSticky.vue'),
    FreeShippingCoupon: () => import(/* webpackChunkName: "free-shipping-coupon-comp" */'./frame-component/FreeShippingCoupon.vue'),
  },
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    getAnalysisData: {
      type: Function,
      default: () => {}
    },
    isSticky: {
      type: Boolean,
      default: false
    },
    metaData: {
      type: Object,
      default: () => {}
    },
    showItems: {
      type: Array,
      default() {
        return []
      }
    },
    // propData: {
    //   type: Object,
    //   default: () => {}
    // },
    isNewUserStyle: {
      type: Boolean,
      default: false
    },
    isStickyNewUser: {
      type: Boolean,
      default: false
    },
    getSelfCoupon: {
      type: Object,
      default: () => {}
    },
    isShowShippingCoupon: {
      type: Boolean,
      default: false
    },
    onlySingleStylePage: {
      type: Boolean,
      default: false
    },
    customComponentName: {
      type: String,
      default: ''
    },
    pageFrom: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      containerStyle: {
        'backgroundColor': ''
      }
    }
  },
  watch: {
    containerStyle: {
      handler(val) {
        this.$nextTick(() => {
          this.$emit('getContainerStyle', val)
        })
      },
      deep: true
    }
  },
  methods: {
    isHasNewUserCoupon(item) {
      return this.isNewUserStyle && item.isNewUserFrame == '1' && item.couponDetailList?.length > 0
    },
    isNewUserSticky(showItems) {
      return this.isNewUserStyle && showItems.filter(item => item.isNewUserFrame != '1').length > 0
    },
    getDynamicStyle(item) {
      const GB_cssRight = this.GB_cssRight
      const metaData = this.$attrs?.propData?.props?.metaData ?? {}
      const firstItem = this.$attrs?.propData?.props?.items?.[0] ?? {}
      // 优先先取组件维度，再取坑位维度
      const textColor = metaData.showCustomBg == '1' ? (metaData.textColor || '#5C0B0B') : 
        firstItem?.showCustomBg == '1' ? firstItem?.textColor || '#5C0B0B' : '#5C0B0B'
      const bgColor = metaData.showCustomBg == '1' ? (metaData.bgColor || '#FCF5E6') : 
        firstItem?.showCustomBg == '1' ?  firstItem?.bgColor || '#FCF5E6' : '#FCF5E6'
      // single-free-3e9afaec82 单列
      // single-free-ar-aa9baefec1 单列ar
      // two-free-2b6aece2d7 双列
      // two-free-ar-71fcf1f882 双列ar
      let bg, border, borderRadius
      if(metaData.showCustomBg == '1') {
        // 背景图片
        bg = metaData.bgType == 2 ? `url(${ metaData?.bgImage?.src }) no-repeat` : `${ bgColor }`
        border = 'none'
      } else {
        // 纯色 + 蒙层
        if(this.isSticky && !GB_cssRight) {
          // 单行背景色 非ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/single-free-3e9afaec82.png') no-repeat top right / contain, ${bgColor} `
        } else if(!this.isSticky && !GB_cssRight){
          // 两列背景色 非ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/two-free-2b6aece2d7.png') no-repeat top right / contain, ${bgColor} `
        } else if(this.isSticky && GB_cssRight) {
          // 单行背景色 ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/single-free-ar-aa9baefec1.png') no-repeat top left / contain, ${bgColor} `
        } else if(!this.isSticky && GB_cssRight) {
          // 两列背景色 ar
          bg = `url('/pwa_dist/images/ccc/freeshipping/two-free-ar-71fcf1f882.png') no-repeat top left / contain, ${bgColor} `
        }
        border = `0.4px solid ${hexToRGBA(textColor, 0.1)}`
      }
      if (this.metaData?.isNewUserStyle) { // 免邮
        bg = hexToRGBA(metaData.freeShippingColor, 0.07)
        border = 'none'
      }
      if (this.isHasNewUserCoupon(item)) { // 券
        bg = hexToRGBA(metaData.couponFrameBgColor, 1)
        border = `0.5px solid ${hexToRGBA(metaData.couponFrameTitleColor, 0.1)}`
        if(metaData?.couponBackgroundImage?.src){
          bg = `url(${metaData.couponBackgroundImage.src}) no-repeat`
        }
        // 列表不需要描边
        if (this.pageFrom == 'list') {
          border = 'none'
          borderRadius = '0px'
        }
      }
      // 父元素设置背景色减少轮播闪烁的视觉差
      this.containerStyle.backgroundColor = bg
      return {
        'background': bg,
        'border': border,
        'backgroundSize': 'cover',
        'height': this.isHasNewUserCoupon(item) ? 'calc(100% - 1px)' : '',
        'borderRadius': borderRadius || '',
      }
    },
  }
}
</script>
